/* eslint-disable no-restricted-imports */
// Allow specific modules from react-bootstrap for use in MKSAP.
import * as ReactBootstrap from "react-bootstrap";
import type { BsPrefixRefForwardingComponent } from "react-bootstrap/helpers";
import { FC } from "react";
export { Overlay } from "./Overlay";

export const {
	Accordion,
	Card,
	CardGroup,
	Carousel,
	CloseButton,
	Col,
	Collapse,
	Fade,
	FormLabel,
	FormGroup,
	FormControl,
	FormText,
	FormSelect,
	FormCheck,
	InputGroup,
	ListGroup,
	Nav,
	Navbar,
	NavItem,
	NavLink,
	Offcanvas,
	OverlayTrigger,
	Pagination,
	Popover,
	Row,
	Table,
	Tooltip,
	Toast,
	ToastContainer,
	useAccordionButton,
} = ReactBootstrap;

// declare type MkAccordion = BsPrefixRefForwardingComponent<
// 	"div",
// 	ReactBootstrap.AccordionProps
// > & {
// 	Button: BsPrefixRefForwardingComponent<
// 		"div",
// 		Pick<ReactBootstrap.AccordionButtonProps, "onClick">
// 	>;
// 	Collapse: FC<
// 		React.PropsWithChildren<
// 			Pick<ReactBootstrap.AccordionCollapseProps, "eventKey">
// 		>
// 	>;
// };
// export const Accordion: MkAccordion = ReactBootstrap.Accordion;

export const Alert: FC<
	React.PropsWithChildren<
		Pick<
			ReactBootstrap.AlertProps,
			"variant" | "className" | "dismissible" | "onClose"
		>
	>
> = ReactBootstrap.Alert;

export const Badge: FC<
	React.PropsWithChildren<Pick<ReactBootstrap.BadgeProps, "bg" | "className">>
> = ReactBootstrap.Badge;

export const ButtonGroup: FC<
	React.PropsWithChildren<Pick<ReactBootstrap.ButtonGroupProps, "className">>
> = ReactBootstrap.ButtonGroup;

export const Container: FC<
	React.PropsWithChildren<
		Pick<ReactBootstrap.ContainerProps, "className" | "fluid">
	>
> = ReactBootstrap.Container;

// **Deprecated**
// export const FormRow: FC<
// 	Pick<React.HTMLAttributes<HTMLDivElement>, "id" | "className">
// > = ReactBootstrap.Form.Row;

export const Form: FC<
	React.PropsWithChildren<Pick<ReactBootstrap.FormProps, "onSubmit">>
> = ReactBootstrap.Form;

export const ListGroupItem: BsPrefixRefForwardingComponent<
	"a",
	Pick<
		ReactBootstrap.ListGroupItemProps,
		"action" | "eventKey" | "active" | "onClick"
	>
> = ReactBootstrap.ListGroupItem;
